import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            dark: {
                primary: '#393E46',
                secondary: '#6D9886',
                accent: '#F2E7D5',
                error: '#b71c1c',
            },
        },
    },
    icons: {
        iconfont: 'fa',
      },
});
